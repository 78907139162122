var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/components/IconFontSelect/index.tsx
var IconFontSelect_exports = {};
__export(IconFontSelect_exports, {
  ICON_FONT_KEYS: () => ICON_FONT_KEYS,
  IconFont: () => IconFont,
  IconFontSelect: () => IconFontSelect
});
module.exports = __toCommonJS(IconFontSelect_exports);
var import_antd = require("antd");
var import_icons = require("@ant-design/icons");
var import_pro_components = require("@ant-design/pro-components");
var import_react = __toESM(require("react"));
var ICON_FONT_KEYS = [
  "icon-tuichu",
  "icon-fanhui",
  "icon-facebook",
  "icon-twitter",
  "icon-xiangyou",
  "icon-right",
  "icon-fanhui1",
  "icon-fenxiang",
  "icon-xiangxia",
  "icon-xiangxia1",
  "icon-xiangxia2",
  "icon-suofang",
  "icon-chexiao",
  "icon-esc",
  "icon-chexiao1",
  "icon-iconfont",
  "icon-suoding",
  "icon-bianji",
  "icon-shoucang2",
  "icon-xinjian",
  "icon-shoucang1",
  "icon-gongkai",
  "icon-gouwuche1",
  "icon-zhongwen",
  "icon-shangchuan",
  "icon-yingwen",
  "icon-gouwuche2",
  "icon-shanchu",
  "icon-xiazai",
  "icon-sousuo",
  "icon-dashang",
  "icon-xiangmu",
  "icon-fuzhidaima1",
  "icon-wofaqi",
  "icon-xiangmuchengyuan",
  "icon-gengduo",
  "icon-wocanyu",
  "icon-lishi",
  "icon-piliang",
  "icon-shijian",
  "icon-gonggao",
  "icon-weixin",
  "icon-weibo",
  "icon-gerenzhanghu",
  "icon-tianjiachengyuan",
  "icon-soutubiao",
  "icon-souren",
  "icon-yuzhanghao",
  "icon-biaoqing",
  "icon-qq",
  "icon-weibo1",
  "icon-zuoxuan",
  "icon-fangda2",
  "icon-zuo2",
  "icon-suoxiao",
  "icon-you2",
  "icon-suoxiao2",
  "icon-youxuan2",
  "icon-zuo",
  "icon-zuoxuan2",
  "icon-shang",
  "icon-shang2",
  "icon-youxuan",
  "icon-xia2",
  "icon-fangda",
  "icon-xia",
  "icon-you",
  "icon-zhuanrang",
  "icon-dianzan",
  "icon-huifu",
  "icon-saoyisao",
  "icon-shuoming",
  "icon-jinggao",
  "icon-jieshi",
  "icon-youxiang",
  "icon-guanbi",
  "icon-qunzhu",
  "icon-fuzhichenggong",
  "icon-weijiaru",
  "icon-daishenhe",
  "icon-shenhetongguo",
  "icon-shenhejujue",
  "icon-xinjiantubiaoku",
  "icon-tubiaoku",
  "icon-gouwuche",
  "icon-huidingbu",
  "icon-dianzan1",
  "icon-morentouxiang",
  "icon-paixu",
  "icon-wenjian",
  "icon-github",
  "icon-yuzhanghao1",
  "icon-weibo2",
  "icon-you1",
  "icon-zuo1",
  "icon-shang1",
  "icon-iconfont1",
  "icon-gonggaodayi",
  "icon-gongnengjieshao",
  "icon-tubiaohuizhi",
  "icon-daimayingyong",
  "icon-zhifubao",
  "icon-alibaba",
  "icon-xiaomi",
  "icon-zhongguodianxin",
  "icon-tianmao",
  "icon-alimama",
  "icon-zhubajie",
  "icon-tengxunwang",
  "icon-aliyun",
  "icon-taobaowang",
  "icon-anzhuo",
  "icon-ios",
  "icon-pcduan",
  "icon-qingchu",
  "icon-huizhiguize",
  "icon-zhizuoliucheng",
  "icon-fuzhidaima",
  "icon-fankui1",
  "icon-weitijiao",
  "icon-chexiao2"
];
var IconFont = (0, import_icons.createFromIconfontCN)({
  scriptUrl: "//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js"
});
var IconFontSelect = (props) => {
  const [items, setItems] = (0, import_react.useState)(ICON_FONT_KEYS);
  const [inputText, setInputText] = (0, import_react.useState)("");
  const inputRef = (0, import_react.useRef)(null);
  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, inputText]);
    setInputText("");
    setTimeout(() => {
      var _a;
      (_a = inputRef.current) == null ? void 0 : _a.focus();
    }, 0);
  };
  return /* @__PURE__ */ import_react.default.createElement(
    import_pro_components.ProFormSelect,
    {
      ...props,
      initialValue: props.value,
      onChange: props.onChange,
      fieldProps: {
        dropdownRender: (menu) => {
          return /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, menu, /* @__PURE__ */ import_react.default.createElement(import_antd.Divider, { style: { margin: "8px 0" } }), /* @__PURE__ */ import_react.default.createElement(import_antd.Space, { style: { padding: "0 8px 4px" } }, /* @__PURE__ */ import_react.default.createElement(
            import_antd.Input,
            {
              placeholder: "Please enter icon",
              ref: inputRef,
              value: inputText,
              onChange: (event) => setInputText(event.target.value),
              onKeyDown: (e) => e.stopPropagation()
            }
          ), /* @__PURE__ */ import_react.default.createElement(import_antd.Button, { type: "text", icon: /* @__PURE__ */ import_react.default.createElement(import_icons.PlusOutlined, null), onClick: addItem }, "Add ICON")));
        }
      },
      options: items.map((icon, index) => {
        return {
          key: index,
          label: /* @__PURE__ */ import_react.default.createElement(import_antd.Space, null, ICON_FONT_KEYS.includes(icon) && /* @__PURE__ */ import_react.default.createElement(IconFont, { size: props.iconSize || 32, type: icon }), /* @__PURE__ */ import_react.default.createElement("div", null, icon)),
          value: icon
        };
      })
    }
  );
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  ICON_FONT_KEYS,
  IconFont,
  IconFontSelect
});
