export var version = {
  "@ant-design/pro-card": "2.8.8",
  "@ant-design/pro-components": "2.7.18",
  "@ant-design/pro-descriptions": "2.5.53",
  "@ant-design/pro-field": "2.16.2",
  "@ant-design/pro-form": "2.30.2",
  "@ant-design/pro-layout": "7.20.1",
  "@ant-design/pro-list": "2.5.69",
  "@ant-design/pro-provider": "2.14.9",
  "@ant-design/pro-skeleton": "2.1.13",
  "@ant-design/pro-table": "3.17.2",
  "@ant-design/pro-utils": "2.15.18"
};