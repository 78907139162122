var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/components/FormCascader/index.tsx
var FormCascader_exports = {};
__export(FormCascader_exports, {
  default: () => FormCascader_default
});
module.exports = __toCommonJS(FormCascader_exports);
var import_react = __toESM(require("react"));
var import_ServiceRequest = require("../../services/base/ServiceRequest");
var import_antd = require("antd");
var FormCascader = (props) => {
  const { Text } = import_antd.Typography;
  const { dataRequest, key = Math.random(), dataNames, value, onChange } = props;
  const level = props.level || 6;
  const idKey = (dataNames == null ? void 0 : dataNames.id) || "id";
  const nameKey = (dataNames == null ? void 0 : dataNames.name) || "name";
  const parentKey = (dataNames == null ? void 0 : dataNames.parent) || "parentId";
  const childrenKey = (dataNames == null ? void 0 : dataNames.children) || "children";
  let loadValue = value && value !== "" && level > 1;
  const [options, setOptions] = (0, import_react.useState)([]);
  const [defValue, setDefValue] = (0, import_react.useState)([]);
  const [showValue, setShowValue] = (0, import_react.useState)();
  (0, import_react.useEffect)(() => {
    (0, import_ServiceRequest.cascaderRequest)(dataRequest, dataNames, level).then((records) => {
      if (records && records.length > 0) {
        setOptions([...records]);
      }
    });
  }, []);
  (0, import_react.useEffect)(() => {
    console.log("----------------------------cascader value->" + value);
    if (loadValue) {
    }
    if (value && (showValue === null || showValue === void 0)) {
      loadById(value);
    }
  }, [value, options]);
  const loadById = (id) => {
    (0, import_ServiceRequest.cascaderRequest)({ ...dataRequest, params: { [idKey]: id } }, { ...dataNames }).then((result) => {
      if (result && result.length > 0) {
        if (result[0].fullName) {
          setShowValue(result[0].fullName);
        } else {
          setShowValue(result[0].name);
        }
      }
    });
  };
  const buildDefValue = (id, ds, parentRecord, records) => {
    let values = ds ? ds : [id];
    records.map((record) => {
      var _a;
      (_a = record.children) == null ? void 0 : _a.map((child) => {
        if (child.value === id) {
          values.push(record.value);
        } else if (child.children) {
          buildDefValue(id, values, child, child.children);
        }
      });
    });
    return values;
  };
  const buildParent = (record, ps) => {
    let parents = ps ? ps : [];
    let parent = Reflect.get(record, parentKey);
    if (parent) {
      parents.push(parent);
      buildParent(parent, parents);
    }
    return parents;
  };
  const getPath = (data, id) => {
    let tempPath = [];
    try {
      data.map((node) => {
        getNodePath(node, tempPath, id);
      });
    } catch (e) {
      console.log(e, tempPath);
    }
    return tempPath;
  };
  const getNodePath = (node, tempPath, id) => {
    tempPath.push(node.value);
    if (node.value == id) {
      throw "获得数据!";
    }
    if (node.children) {
      node.children.map((item) => {
        getNodePath(item, tempPath, id);
      });
      tempPath.pop();
    } else {
      tempPath.pop();
    }
  };
  const onChangeHandle = (changeValue, selectOptions) => {
    if (onChange && changeValue) {
      onChange(changeValue[changeValue.length - 1]);
    }
  };
  const onClear = () => {
    setShowValue("");
    if (onChange) {
      onChange(null);
    }
  };
  const loadData = (selectedOptions) => {
    console.log("----------------cascader loadData->" + (selectedOptions ? selectedOptions[selectedOptions.length - 1].value : null));
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    if (!targetOption.isLeaf && (!targetOption.children || targetOption.children.length === 0)) {
      let params = { [parentKey]: targetOption.value };
      (0, import_ServiceRequest.cascaderRequest)({ ...dataRequest, params }, dataNames, level).then((records) => {
        setOptions((origin) => updateTreeData(origin, targetOption.value, records));
      }).finally(() => {
        targetOption.loading = false;
      });
    }
  };
  const setLeaf = () => {
    options == null ? void 0 : options.map((l1) => {
      if (level === 1) {
        l1.isLeaf = true;
      } else {
        hasLeaf(l1, 1);
      }
    });
  };
  const hasLeaf = (curr, l) => {
    var _a;
    l++;
    (_a = curr.children) == null ? void 0 : _a.map((next) => {
      if (l >= level) {
        next.isLeaf = true;
        hasLeaf(next, l);
      }
    });
  };
  function updateTreeData(list, value2, children, currLevel) {
    return list.map((node) => {
      if (node.value === value2) {
        return {
          ...node,
          children
        };
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, value2, children)
        };
      }
      return node;
    });
  }
  console.log("--------------------------------------cascader render->" + defValue);
  return /* @__PURE__ */ import_react.default.createElement(
    import_antd.Cascader,
    {
      defaultValue: defValue,
      style: { width: props.width },
      options,
      loadData,
      onChange: onChangeHandle,
      onClear,
      placeholder: /* @__PURE__ */ import_react.default.createElement(Text, null, showValue),
      changeOnSelect: true,
      allowClear: true
    }
  );
};
var FormCascader_default = FormCascader;
