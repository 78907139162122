var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/components/FormOptionEditor/index.tsx
var FormOptionEditor_exports = {};
__export(FormOptionEditor_exports, {
  default: () => FormOptionEditor_default
});
module.exports = __toCommonJS(FormOptionEditor_exports);
var import_react = __toESM(require("react"));
var import_pro_components = require("@ant-design/pro-components");
function FormOptionEditor(props) {
  const {
    value = "[[]]",
    // [[key,value],[key,value]]
    onChange,
    maxLength = 20
  } = props;
  const [dataSource, setDataSource] = (0, import_react.useState)([]);
  const [editableKeys, setEditableRowKeys] = (0, import_react.useState)();
  const buildValue = () => {
    const valueArr = JSON.parse(value);
    return valueArr.map((row, index) => {
      return { id: index, key: row[0], value: row[1] };
    });
  };
  const newValue = () => {
    return { id: Date.now() };
  };
  (0, import_react.useEffect)(() => {
    let optionItems = buildValue();
    if (optionItems.length === 0) {
      optionItems.push(newValue());
    }
    setDataSource(optionItems);
    setEditableRowKeys(optionItems.map((item) => item.id));
  }, []);
  (0, import_react.useEffect)(() => {
    if (onChange) {
      onChange(JSON.stringify(dataSource.map((item) => [item.key, item.value])));
    }
  }, [dataSource]);
  const columns = [
    {
      title: "Key",
      dataIndex: "key",
      valueType: "text",
      width: 100,
      fieldProps: {
        required: true,
        placeholder: "Key"
      }
    },
    {
      title: "Value",
      dataIndex: "value",
      valueType: "text",
      width: 100,
      fieldProps: {
        required: true,
        placeholder: "Value"
      }
    },
    {
      title: "操作",
      valueType: "option",
      width: 40,
      render: () => {
        return null;
      }
    }
  ];
  return /* @__PURE__ */ import_react.default.createElement(
    import_pro_components.EditableProTable,
    {
      rowKey: "id",
      value: dataSource,
      onChange: setDataSource,
      columns,
      maxLength,
      showHeader: false,
      editable: {
        type: "multiple",
        editableKeys,
        actionRender: (row, config, defaultDoms) => {
          return [defaultDoms.delete];
        },
        onValuesChange: (record, recordList) => {
          setDataSource(recordList);
        },
        onChange: setEditableRowKeys
      },
      recordCreatorProps: {
        newRecordType: "dataSource",
        position: "bottom",
        record: () => newValue(),
        creatorButtonText: "新增选项"
      }
    }
  );
}
var FormOptionEditor_default = FormOptionEditor;
