import React, {useCallback, useRef} from 'react';
import {LogoutOutlined, SafetyCertificateOutlined} from '@ant-design/icons';
import {Spin} from 'antd';
import {stringify} from 'querystring';
import {useModel} from "@@/plugin-model";
import {history} from '@umijs/max';
import HeaderDropdown from '../HeaderDropdown';
import {requestLogout} from "@oakjs/antd-pro/dist/cjs/services/base/ServiceRequest";
import type {MenuInfo} from 'rc-menu/lib/interface';
import ChangePwdModal, {ChangePwdModalActionType} from "../ChangePwdModal";
import {flushSync} from "react-dom";
import {useEmotionCss} from "@ant-design/use-emotion-css";

export type GlobalHeaderRightProps = {
  menu?: boolean;
  children?: React.ReactNode;
};

export const AvatarName = () => {
  const {initialState} = useModel('@@initialState');
  const {currentUser} = initialState || {};
  return <span className={`anticon`}>{currentUser?.fullName}</span>;
};

export const AvatarDropdown: React.FC<GlobalHeaderRightProps> = ({menu, children}) => {

  /**
   * 退出登录，并且将当前的 url 保存
   */
  const loginOut = async () => {

    // logout
    await requestLogout()

    // @ts-ignore
    const {search, pathname} = window.location;
    const urlParams = new URL(window.location.href).searchParams;
    /** 此方法会跳转到 redirect 参数所在的位置 */
    const redirect = urlParams.get('redirect');

    // Note: There may be security issues, please note
    if (window.location.pathname !== '/login' && !redirect) {
      history.replace({
        pathname: '/login',
        search: stringify({
          redirect: pathname + search,
        }),
      });
    }
  };

  const changePwdRef = useRef<ChangePwdModalActionType>();

  const actionClassName = useEmotionCss(({token}) => {
    return {
      display: 'flex',
      height: '48px',
      marginLeft: 'auto',
      overflow: 'hidden',
      alignItems: 'center',
      padding: '0 8px',
      cursor: 'pointer',
      borderRadius: token.borderRadius,
      '&:hover': {
        backgroundColor: token.colorBgTextHover,
      },
    };
  });
  const {initialState, setInitialState} = useModel('@@initialState');

  const onMenuClick = useCallback(
    (event: MenuInfo) => {
      const {key} = event;
      if (key === 'logout') {
        flushSync(() => {
          setInitialState((s) => ({...s, currentUser: undefined}));
        });
        loginOut();
        return;
      } else if (key === 'pwd') {
        changePwdRef.current?.open();
        return;
      }
      history.push(`/account/${key}`); // TODO
    },
    [setInitialState],
  );

  const loading = (
    <span className={actionClassName}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );

  if (!initialState) {
    return loading;
  }

  const {currentUser} = initialState;

  if (!currentUser || !currentUser.userId) {
    return loading;
  }

  const menuItems = [
    ...(menu
      ? [
        // TODO
      ]
      : []),
    {
      key: 'pwd',
      icon: <SafetyCertificateOutlined/>,/*TODO 未展示*/
      label: '修改密码'
    }, {
      key: 'd2',
      type: 'divider' as const
    },
    {
      key: 'logout',
      icon: <LogoutOutlined/>,
      label: '退出登录',
    },
  ];

  return <> <HeaderDropdown
    menu={{
      selectedKeys: [],
      onClick: onMenuClick,
      items: menuItems,
    }}
  >
    {children}
  </HeaderDropdown>
    <ChangePwdModal actionRef={changePwdRef}/>
  </>;
};
