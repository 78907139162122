var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/components/FormUpload/index.tsx
var FormUpload_exports = {};
__export(FormUpload_exports, {
  default: () => FormUpload_default
});
module.exports = __toCommonJS(FormUpload_exports);
var import_pro_form = require("@ant-design/pro-form");
var import_EnvVariableConfiguration = require("../../env/EnvVariableConfiguration");
var import_react = __toESM(require("react"));
var import_antd_img_crop = __toESM(require("antd-img-crop"));
var valueOf = (v) => {
  if (v) {
    const valueArr = v.split(",");
    const uploadFiles = [];
    const timestamp = Date.now();
    valueArr.forEach((url, index) => {
      const uploadFile = {
        uid: `__AUTO__${timestamp}_${index}__`,
        name: url,
        // TODO name获取
        status: "done",
        url,
        thumbUrl: url
      };
      uploadFiles.push(uploadFile);
    });
    return uploadFiles;
  }
  return [];
};
function FormUpload(props) {
  const {
    valueType = "image",
    options = {
      size: { text: "文件大小", status: "1MB" },
      type: { text: "文件类型", status: "" },
      count: { text: "文件数量", status: "1" },
      option: { text: "文件选项", status: "" },
      tooltip: { text: "提示", status: void 0 }
    },
    crop = false,
    value,
    onChange,
    ...rest
  } = props;
  const [files, setFiles] = (0, import_react.useState)([]);
  (0, import_react.useEffect)(() => {
    if (value && value !== "") {
      console.log(`FormUpload init->${value}`);
      setFiles(() => valueOf(value));
    }
  }, []);
  const max = options["count"].status;
  const tooltip = options["tooltip"].status;
  const beforeUpload = () => {
    return (file, FileList) => {
      return true;
    };
  };
  const handleOnChange = (value2) => {
    var _a;
    let urls = "";
    (_a = value2.fileList) == null ? void 0 : _a.map((file) => {
      if (file.status === "done" && file.response) {
        const { thumbUrl, url } = file.response;
        if (thumbUrl && url) {
          file.thumbUrl = thumbUrl;
          file.url = url;
        }
      }
      if (file.url != null) {
        urls += `${file.url},`;
      }
      return file;
    });
    console.log(`FormUpload onChange->${urls}`, onChange);
    if (onChange && urls.endsWith(",")) {
      onChange(urls.substring(0, urls.length - 1));
    }
    setFiles(() => value2.fileList);
    return value2.fileList;
  };
  const build = (children) => {
    const isImage = valueType.startsWith("image");
    const isCrop = crop || options["option"].status !== "" && options["option"].status !== "0";
    return isCrop && isImage ? /* @__PURE__ */ import_react.default.createElement(import_antd_img_crop.default, { rotationSlider: true }, children) : /* @__PURE__ */ import_react.default.createElement(import_react.default.Fragment, null, children);
  };
  return build(/* @__PURE__ */ import_react.default.createElement(
    import_pro_form.ProFormUploadButton,
    {
      ...rest,
      max,
      value: files,
      fieldProps: {
        withCredentials: true,
        data: { type: "antd" },
        listType: valueType.startsWith("image") ? "picture-card" : "text",
        beforeUpload: beforeUpload(),
        maxCount: max
      },
      onChange: handleOnChange,
      action: import_EnvVariableConfiguration.UPLOAD_URL,
      extra: tooltip || rest.extra
    }
  ));
}
var FormUpload_default = FormUpload;
