var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/components/FormAttrEditor/index.tsx
var FormAttrEditor_exports = {};
__export(FormAttrEditor_exports, {
  default: () => FormAttrEditor_default
});
module.exports = __toCommonJS(FormAttrEditor_exports);
var import_react = __toESM(require("react"));
var import_pro_components = require("@ant-design/pro-components");
var import_antd = require("antd");
var import_ServiceRequest = require("../../services/base/ServiceRequest");
function FormAttrEditor(props) {
  const {
    value,
    // {key: value}
    onChange,
    requestUrl,
    params
  } = props;
  console.log("------------------FormAttrEditor->", props);
  const [items, setItems] = (0, import_react.useState)([]);
  const [initialValue, setInitialValue] = (0, import_react.useState)(value ? JSON.parse(value) : {});
  const [data, setData] = (0, import_react.useState)({});
  const formRef = (0, import_react.useRef)();
  (0, import_react.useEffect)(() => {
    let url = requestUrl ? (!requestUrl.startsWith("/") ? "/" : "") + requestUrl + "?" + params : "/attr/select?" + params;
    if (!url.includes("undefined")) {
      (0, import_ServiceRequest.selectRequest)({ url })().then((resp) => {
        setItems(resp);
      });
    }
  }, [params]);
  (0, import_react.useEffect)(() => {
    if (onChange) {
      onChange(JSON.stringify(data));
    }
  }, [data]);
  return items && items.length > 0 ? /* @__PURE__ */ import_react.default.createElement(
    import_pro_components.ProForm,
    {
      formRef,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      layout: "horizontal",
      submitter: false
    },
    items.map((item, index) => {
      var _a;
      return /* @__PURE__ */ import_react.default.createElement(
        import_pro_components.ProForm.Item,
        {
          key: index,
          label: item.label,
          name: item.key || item.variable,
          initialValue: initialValue[item.key || item.variable] || item.defaultValue,
          required: item.required
        },
        (item.valueType === "TEXT" || item.type === "TEXT") && /* @__PURE__ */ import_react.default.createElement(import_antd.Input, { required: item.required, onChange: (value2) => {
          setData({ ...data, [item.key || item.variable]: value2 });
        } }),
        (item.valueType === "INT" || item.type === "INT") && /* @__PURE__ */ import_react.default.createElement(import_antd.Input, { type: "number", required: item.required, onChange: (value2) => {
          setData({ ...data, [item.key || item.variable]: value2 });
        } }),
        (item.valueType === "NUMBER" || item.type === "NUMBER") && /* @__PURE__ */ import_react.default.createElement(import_antd.Input, { type: "number", required: item.required, onChange: (value2) => {
          setData({ ...data, [item.key || item.variable]: value2 });
        } }),
        (item.valueType === "BOOLEAN" || item.type === "BOOLEAN") && /* @__PURE__ */ import_react.default.createElement(import_antd.Input, { type: "checkbox", required: item.required, onChange: (value2) => {
          setData({ ...data, [item.key || item.variable]: value2 });
        } }),
        (item.valueType === "OPTION" || item.type === "OPTION") && /* @__PURE__ */ import_react.default.createElement(import_antd.Select, { onChange: (value2) => {
          setData({ ...data, [item.key || item.variable]: value2 });
        }, options: (_a = item.optionsList) == null ? void 0 : _a.map((row) => {
          return { label: row[0], value: row[1] };
        }) })
      );
    })
  ) : /* @__PURE__ */ import_react.default.createElement("span", null, "暂无关联选项");
}
var FormAttrEditor_default = FormAttrEditor;
