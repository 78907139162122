var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/provider/AppProvider/index.tsx
var AppProvider_exports = {};
__export(AppProvider_exports, {
  buildBreadcrumb: () => buildBreadcrumb,
  initialState: () => initialState
});
module.exports = __toCommonJS(AppProvider_exports);
var import_ServiceRequest = require("../../services/base/ServiceRequest");
var import_EnvVariableConfiguration = require("../../env/EnvVariableConfiguration");
var initialState = async (history, defaultSettings) => {
  const fetchUserInfo = async () => {
    try {
      return await (0, import_ServiceRequest.currentAdminRequest)();
    } catch (error) {
      console.log("fetchUserInfo error->" + error);
      history.push(import_EnvVariableConfiguration.LOGIN_URL);
    }
    return void 0;
  };
  const fetchMenus = async () => {
    return await (0, import_ServiceRequest.requestMenus)({}, []);
  };
  const configs = await (0, import_ServiceRequest.requestConfigs)();
  const getConfig = (name) => {
    return configs ? configs[name] : "";
  };
  if ((history == null ? void 0 : history.location.pathname) === import_EnvVariableConfiguration.LOGIN_URL || (history == null ? void 0 : history.location.pathname) === "/" || (history == null ? void 0 : history.location.pathname) === "/logout") {
    return {
      fetchUserInfo,
      fetchMenus,
      settings: defaultSettings,
      configs,
      getConfig
    };
  } else {
    try {
      const currentUser = await fetchUserInfo();
      const menus = await fetchMenus();
      return {
        fetchUserInfo,
        fetchMenus,
        currentUser,
        settings: defaultSettings,
        schemaConfig: void 0,
        menus,
        configs,
        getConfig
      };
    } catch (e) {
      return {
        fetchUserInfo,
        fetchMenus,
        settings: defaultSettings,
        configs,
        getConfig
      };
    }
  }
};
var buildBreadcrumb = (pathname, initialState2) => {
  const breadcrumbs = [];
  const currMenus = findMenuPath(pathname, initialState2);
  const menuLevel = (currMenus == null ? void 0 : currMenus.length) || 0;
  currMenus.forEach((menu, index) => {
    breadcrumbs.push({ path: "", breadcrumbName: menu.name + (index === menuLevel - 1 ? " " : ""), key: menu.key });
  });
  return breadcrumbs;
};
var findMenuPath = (pathname, initialState2) => {
  try {
    const hasSecond = pathname.indexOf("/", 1);
    const path = hasSecond > -1 ? pathname.substring(0, hasSecond + 1) : pathname;
    const currMenus = [];
    let firstMenus = (initialState2 == null ? void 0 : initialState2.menus.length) === 1 ? initialState2 == null ? void 0 : initialState2.menus[0].routes : initialState2 == null ? void 0 : initialState2.menus;
    firstMenus == null ? void 0 : firstMenus.forEach((first) => {
      var _a;
      if (first.path.startsWith(path)) {
        currMenus[0] = first;
      }
      (_a = first.routes) == null ? void 0 : _a.forEach((second) => {
        var _a2;
        if (second.path.startsWith(path)) {
          currMenus[0] = first;
          currMenus[1] = second;
        }
        (_a2 = second.routes) == null ? void 0 : _a2.forEach((third) => {
          if (third.path.startsWith(path)) {
            currMenus[0] = first;
            currMenus[1] = second;
            currMenus[2] = third;
          }
        });
      });
    });
    return currMenus;
  } catch (e) {
    return [];
  }
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  buildBreadcrumb,
  initialState
});
