import {useModel} from "@@/plugin-model";
import {InitialState} from "@oakjs/antd-pro/dist/cjs/provider/AppProvider";

export const passPaths: string[] = ['/403', '/404'];

export const checkPrivilege = (path: string, initialStateParam?: InitialState): boolean => {

  if (!initialStateParam) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {initialState} = useModel('@@initialState');
    // eslint-disable-next-line no-param-reassign
    initialStateParam = initialState;
  }

  // @ts-ignore
  const {currentUser} = initialStateParam;

  if (passPaths.includes(path)) {
    return true;
  }

  let pathStr = path;
  // 规范访问路径（/xxx 转为 /xxx/index）
  const c = path.split('/').length - 1;
  if (c === 1) {
    pathStr = path + '/index';
  }

  // @ts-ignore
  const pass = currentUser?.limits?.findIndex((item) => item === pathStr) !== -1 || currentUser?.root || currentUser?.mc;
  // console.log(pathStr + ' -> ' + pass)
  return pass;
}

