import { DefaultFooter } from '@ant-design/pro-layout';
// @ts-ignore
import {useModel} from "@@/plugin-model";
const Footer: React.FC = () => {
  const { initialState } = useModel('@@initialState');
  // @ts-ignore
  const { getConfig } = initialState;
  const defaultMessage = getConfig?.('company_name');
  return (
    <DefaultFooter
      copyright={`${new Date().getFullYear()} ${defaultMessage}`}
      links={[
        // {
        //   key: 'Ant Design Pro',
        //   title: 'Ant Design Pro',
        //   href: 'https://pro.ant.design',
        //   blankTarget: true,
        // }
      ]}
    />
  );
};

export default Footer;
