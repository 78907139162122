var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/env/EnvVariableConfiguration.ts
var EnvVariableConfiguration_exports = {};
__export(EnvVariableConfiguration_exports, {
  API_ADDRESS: () => API_ADDRESS,
  API_TIMEOUT: () => API_TIMEOUT,
  LAYOUT_NONE_URL: () => LAYOUT_NONE_URL,
  LOGIN_URL: () => LOGIN_URL,
  UPLOAD_URL: () => UPLOAD_URL,
  WELCOME_URL: () => WELCOME_URL
});
module.exports = __toCommonJS(EnvVariableConfiguration_exports);
var API_ADDRESS = process.env.API_ADDRESS || "";
var API_TIMEOUT = parseInt(process.env.API_TIMEOUT || "10000");
var UPLOAD_URL = process.env.UPLOAD_URL || "";
var LOGIN_URL = process.env.LOGIN_URL || "/login";
var WELCOME_URL = process.env.WELCOME_URL || "/welcome";
var LAYOUT_NONE_URL = process.env.LAYOUT_NONE_URL || "";
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  API_ADDRESS,
  API_TIMEOUT,
  LAYOUT_NONE_URL,
  LOGIN_URL,
  UPLOAD_URL,
  WELCOME_URL
});
