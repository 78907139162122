var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/components/FormEditor/index.tsx
var FormEditor_exports = {};
__export(FormEditor_exports, {
  default: () => FormEditor_default
});
module.exports = __toCommonJS(FormEditor_exports);
var import_braft_editor = __toESM(require("braft-editor"));
var import_react = require("react");
var import_dist = require("braft-editor/dist/index.css");
var import_EnvVariableConfiguration = require("../../env/EnvVariableConfiguration");
var upload = (param) => {
  const serverURL = import_EnvVariableConfiguration.UPLOAD_URL;
  const xhr = new XMLHttpRequest();
  const fd = new FormData();
  const successFn = (response) => {
    param.success({
      url: xhr.responseText,
      meta: {
        id: "xxx",
        title: "xxx",
        alt: "xxx",
        loop: true,
        // 指定音视频是否循环播放
        autoPlay: true,
        // 指定音视频是否自动播放
        controls: true,
        // 指定音视频是否显示控制栏
        poster: "http://xxx/xx.png"
        // 指定视频播放器的封面
      }
    });
  };
  const progressFn = (event) => {
    param.progress(event.loaded / event.total * 100);
  };
  const errorFn = (response) => {
    param.error({
      msg: "unable to upload."
    });
  };
  xhr.upload.addEventListener("progress", progressFn, false);
  xhr.addEventListener("load", successFn, false);
  xhr.addEventListener("error", errorFn, false);
  xhr.addEventListener("abort", errorFn, false);
  fd.append("file", param.file);
  fd.append("type", "braft-editor");
  xhr.withCredentials = true;
  xhr.open("POST", serverURL, true);
  xhr.send(fd);
};
var FormEditor = (props) => {
  const {
    value,
    onChange,
    ...rest
  } = props;
  const [content, setContent] = (0, import_react.useState)();
  (0, import_react.useEffect)(() => {
    if (value && value !== "") {
      setContent(import_braft_editor.default.createEditorState(value));
    }
  }, []);
  const handleEditorChange = (editorState) => {
    setContent(() => editorState);
    const htmlContent = editorState.toHTML();
    if (onChange) {
      onChange(htmlContent);
    }
  };
  const submitContent = async () => {
    const htmlContent = content.toHTML();
    if (onChange) {
      onChange(htmlContent);
    }
  };
  return /* @__PURE__ */ React.createElement(
    import_braft_editor.default,
    {
      ...rest,
      value: content,
      onChange: handleEditorChange,
      onSave: submitContent,
      media: { uploadFn: upload }
    }
  );
};
var FormEditor_default = FormEditor;
